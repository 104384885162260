import type * as LabelPrimitive from '@radix-ui/react-label';
import { Slot } from '@radix-ui/react-slot';
import React, { useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { useIntersectionObserver } from 'usehooks-ts';
import { createValidationHandler } from '~/hooks/use-field-validation';
import { cn } from '~/utils/cn';
import { Label } from './Label';
import { textVariants } from './Typography';

type FormItemContextValue = {
  formItemId: string;
  error: [
    string | null | undefined,
    React.Dispatch<React.SetStateAction<string | null | undefined>>,
  ];
};

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

function useFormField() {
  const context = React.useContext(FormItemContext);

  return {
    ...context,
    props: {
      onInvalid: context?.error?.[1] ? createValidationHandler(context?.error[1]) : undefined,
      onInput: context?.error?.[1] ? createValidationHandler(context?.error[1]) : undefined,
    },
  };
}

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const errorState = React.useState<string | null | undefined>(undefined);
    const parentContext = useFormField(); // Only to retrieve error state

    return (
      <FormItemContext.Provider
        value={{
          formItemId: React.useId(),
          error: parentContext?.error || errorState,
        }}
      >
        <div ref={ref} className={cn('group flex flex-col gap-y-2', className)} {...props} />
      </FormItemContext.Provider>
    );
  },
);
FormItem.displayName = 'FormItem';

const FormField = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { error } = useFormField();

    return (
      <div
        ref={ref}
        className={cn(
          'flex flex-col rounded-xl border-2 bg-surface-on px-4 py-1.5 transition-all focus-within:border-foreground focus-within:ring-4 focus-within:ring-foreground/10',
          {
            'border-error focus-within:border-error focus-within:ring-error/10': error[0] != null,
          },
          className,
        )}
        {...props}
      />
    );
  },
);
FormField.displayName = 'FormField';

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ ...props }, ref) => {
  const { formItemId } = useFormField();

  return <Label ref={ref} htmlFor={formItemId} {...props} />;
});
FormLabel.displayName = 'FormLabel';

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ children, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Slot ref={ref} id={formItemId} aria-invalid={!!error[0]} {...props}>
      {children}
    </Slot>
  );
});
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  return (
    <p
      ref={ref}
      className={cn(textVariants({ size: 'm' }), 'font-medium text-subdued-foreground', className)}
      {...props}
    />
  );
});
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const _ref = useRef<HTMLParagraphElement>(null);
  const { isIntersecting, ref: intersectionRef } = useIntersectionObserver({
    threshold: 0.5,
  });
  const { error } = useFormField();
  const body = error[0] ? String(error[0]) : children;

  if (!body) {
    return null;
  }

  return (
    <>
      <p
        ref={mergeRefs([_ref, intersectionRef, ref])}
        className={cn(
          textVariants({ size: 's' }),
          'animate-smooth-height overflow-hidden font-medium text-error fill-mode-both',
          className,
        )}
        {...props}
      >
        {body}
      </p>
      {!isIntersecting ? (
        <div className="fixed top-24 z-50 inset-x-0 grid place-content-center place-items-center">
          <span
            className="bg-error text-on rounded-full px-3 py-2 cursor-pointer"
            onKeyDown={() => {}}
            onClick={() => {
              const parent = _ref.current?.parentNode?.parentNode?.parentNode;
              const parentOffsettop =
                parent != null && 'offsetTop' in parent ? (parent?.offsetTop as number) : null;

              if (parentOffsettop != null) {
                window.scrollTo({
                  top: parentOffsettop - 100,
                  behavior: 'smooth',
                });
              }
            }}
          >
            {body}
          </span>
        </div>
      ) : null}
    </>
  );
});
FormMessage.displayName = 'FormMessage';

export { FormItem, FormField, FormLabel, FormControl, FormDescription, FormMessage, useFormField };
